import React, { useEffect, useState } from 'react';
import Navbar from '../Components/Common/Navbar/Navbar';
import Blog_HeroSection from '../Components/BlogPage/BlogIndvl_Herosection/Blog_HeroSection';
import axios from 'axios';
import { wordpressUrl } from '../Helpers/wordpressUrl';
import { useParams } from 'react-router-dom';
import BlogDetails from '../Components/BlogPage/BlogDetails/BlogDetails';
import Footer from '../Components/Common/Footer/Footer';
import { Helmet } from 'react-helmet';


function BlogSinglePg() {
    const { slug } = useParams();
    const [blog, setBlog] = useState(null);



    useEffect(() => {

        axios.get(`${wordpressUrl}wp-json/wp/v2/blog?slug=${slug}`)
            .then(res => {
                if (res.data.length > 0) {
                    setBlog(res.data[0]);
                    // console.log(res.data[0])
                } else {
                    console.log('No blog post found');
                }
            })
            .catch(error => {
                console.error('Error fetching blog post:', error);
            })

    }, [slug])
    return (
        <div className='BlogSinglePg'>
            <Helmet>
                <title>{blog && blog.title.rendered}</title>
                <link rel="canonical" href={`https://www.docspro.in/blogs/${slug}`}></link>
                {blog && blog.acf.meta_description && <meta name="description"
                    content={blog && blog.acf.meta_description}></meta>}
                <meta property="og:locale" content="en_US"></meta>
                <meta property="og:type" content="article"></meta>
                <meta property="og:title" content={blog && blog.title.rendered}></meta>
                {blog && blog.acf.meta_description && <meta property="og:description" content={blog && blog.acf.meta_description}></meta>}
                <meta property="og:url" content={`https://www.docspro.in/blogs/${slug}`}></meta>
                <meta property="og:site_name" content="DocsPro"></meta>
            </Helmet>
            <Navbar />
            <Blog_HeroSection blogs={blog} />
            <BlogDetails blogs={blog} />
            <Footer />


        </div>
    )
}

export default BlogSinglePg