import React, { useContext } from 'react'
import { Link } from 'react-router-dom';
import '../../../styles/Footer.css'
import MenuContext from '../../Context/menuProvider';
function Footer() {
  const { setImage, image } = useContext(MenuContext)

  return (
    <div className='Footer'>
      <div className="container">
        <div className="row">
          <div className="col">
            <div className="logo">
              <img src={image} alt="WhiteField logo" />

            </div>
            {/* <p className='quote'>Locking Excellence, Opening Peace of Mind!</p> */}
            <div className="social-media-icons">
              <a href="https://www.facebook.com/profile.php?id=61557370717719" target="_blank" rel="noopener noreferrer">
                <i class="fa-brands fa-facebook"></i>

              </a>
              <a href="https://www.instagram.com/docs_pro_/" target="_blank"
                rel="noopener noreferrer">
                <i class="fa-brands fa-instagram"></i>
              </a>
              <a href="mailto:info@hawksee.in" target="_blank"
                rel="noopener noreferrer">
                <i class="fa-solid fa-envelope"></i>
              </a>

            </div>

          </div>
          <div className="col">

            <li>
              <Link to="/">Home</Link>
            </li>
            <li>
              <Link to="/about">About</Link>

            </li>
            <li>
              <Link to="/how-we-work">How We Work</Link>
            </li>





          </div>
          <div className="col">


            <li>
              <Link to="/services">Services</Link>
            </li>
            <li>
              <Link to="/projects">Projects</Link>
            </li>

          </div>
          <div className="col">
            <li>
              <Link to="/blogs">Blogs</Link>
            </li>
            <li>
              <Link to="/contact">contact</Link>
            </li>
          </div>
          <div className="col right">
            <div className="icon">
              <i className="fa-solid fa-location-dot"></i>
            </div>
            <div className="content">
              <p>Mini Bypass Rd, Eranhipaalam,</p>
              <p>Kozhikode, Kerala </p>
              <p>673006</p>
            </div>
          </div>
        </div>

      </div>
      <div className="copyright">
        <p>© Copyright - DocsPro</p>
      </div>
    </div>
  )
}

export default Footer