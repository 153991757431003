import React from 'react'
import Navbar from '../Components/Common/Navbar/Navbar'
import BannerSection from '../Components/Common/BannerSection/BannerSection'
import BannerData from '../Helpers/BannerData';
import Footer from '../Components/Common/Footer/Footer';
import WorkList from '../Components/ProjectsPage/WorkList/WorkList';
import WorksIntro from '../Components/ProjectsPage/WorksIntro/WorksIntro';
import { Helmet } from 'react-helmet';

function Projects() {
    const { ProjectsH2, ProjectsImg } = BannerData;

    return (
        <div className='Projects'>
            <Helmet>
                <title>Our Works - DocsPro | Healthcare Marketing</title>
                <meta name="description"
                    content="View our works at DocsPro. See how our strategies boost visibility, attract patients, and drive growth for medical practices."></meta>
            </Helmet>
            <Navbar />
            <BannerSection h2={ProjectsH2} imgUrl={ProjectsImg} />
            <WorksIntro />
            <WorkList />
            <Footer />
        </div>
    )
}

export default Projects