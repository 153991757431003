import React from 'react';
import '../../../styles/Founder.css'

function Founder() {
    return (
        <div className='Founder'>
            <h2 data-aos="fade-up" data-aos-duration="1000">Discover the Mind Behind Our Mission</h2>
            <div className="container">
                <div className="card-container" data-aos="fade" data-aos-duration="1000">
                    <div className="left-col" data-aos="fade" data-aos-duration="1000">
                        <div className="img-container">
                            <img src="img/1688096545347.jpg" alt="founder" />
                        </div>
                        <div className="social-media-icons">
                            <a href="https://www.facebook.com/Ashraf.Pullambalavan" target="_blank">
                                <i class="fa-brands fa-facebook"></i>

                            </a>
                            <a href="https://instagram.com/_primitive_man?igshid=MzRlODBiNWFlZA==" target="_blank"
                                rel="noopener noreferrer">
                                <i class="fa-brands fa-instagram"></i>
                            </a>
                            <a href="https://www.linkedin.com/in/entreprnr/" target="_blank"
                                rel="noopener noreferrer">
                                <i class="fa-brands fa-linkedin"></i>

                            </a>
                            <a href="https://www.thatmarketingguy.in/" target="_blank"
                                rel="noopener noreferrer">
                                <i class="fa-solid fa-globe"></i>
                            </a>
                        </div>
                    </div>
                    <div className="right-col" data-aos="fade" data-aos-duration="1000">
                        <div className="top-section">
                            <h3>Ashraf Pullambalavan</h3>
                            <h4>Digital Marketing Consultant</h4>
                        </div>
                        <p>
                            I'm Ashraf, your dedicated ally in the dynamic world of healthcare marketing. With a wealth of experience as a seasoned digital strategist, I'm here to empower medical practices as they confidently navigate the digital landscape. Welcome to the cornerstone of our healthcare marketing platform, where innovation and impact intersect.
                            <br></br>
                            <br></br>
                            In this space, we're not solely fixated on metrics like clicks and conversions; we're committed to crafting narratives that deeply resonate with your patients, cultivating authentic connections that foster engagement. From crafting compelling content to analyzing data with precision, we cover every aspect of your digital journey with a focus on elevating your practice's visibility and attracting new patients.</p>
                    </div>
                </div>

            </div>
        </div>
    )
}

export default Founder