import React from 'react';
import '../../../styles/ServiceList.css'

function ServiceList() {
    return (
        <div className='ServiceList'>
            <div className="container">
                <div className="service-section">
                    <div className="top-section" data-aos="fade-up" data-aos-duration="1000">
                        <img src="img/services/branding.png" alt="mission" />
                        <h2 >Branding</h2>
                    </div>
                    <div className="content" data-aos="fade-up" data-aos-duration="1000">
                        <p>At DocsPro, we're passionate about creating powerful brand identities that leave a lasting impact. Drawing from our extensive experience working with a variety of doctor brands, we excel at capturing the essence of each practice to develop unique and compelling brand identities.
                            <br></br>
                            <br></br>
                            Our approach is rooted in deep industry knowledge, allowing us to navigate the complexities of the healthcare sector with precision. By understanding the specific challenges and opportunities within the industry, we craft brand identities that authentically represent our clients' values and aspirations, resonating deeply with their target audience.
                            <br></br>
                            <br></br>
                            Through our strategic branding approach, we empower healthcare professionals to distinguish themselves in a crowded market. Our goal is to help them establish a robust and memorable presence that sets them apart from the competition.</p>
                    </div>
                </div>
                <div className="service-section">
                    <div className="top-section" data-aos="fade-up" data-aos-duration="1000">
                        <img src="img/services/diagram.png" alt="mission" />
                        <h2 >Marketing Strategy Development</h2>
                    </div>
                    <div className="content" data-aos="fade-up" data-aos-duration="1000">
                        <p>We specialize in crafting strategic plans that are meticulously tailored through rigorous competitor analysis and keen observation of market trends. Our expertise lies in deciphering the competitive landscape and discerning emerging market dynamics, allowing us to develop strategies that are finely tuned to the nuances of the healthcare industry.
                            <br></br>
                            <br></br>
                            By leveraging our profound industry knowledge, we ensure that our strategies not only reflect the current market conditions but also anticipate future developments. This proactive approach enables our clients to stay ahead of the curve, positioning them for sustainable growth and success in the ever-evolving healthcare sector. At [Your Company Name], we're committed to delivering strategic solutions that are informed, innovative, and driven by a deep understanding of the complexities of the healthcare landscape.</p>
                    </div>
                </div>
                <div className="service-section">
                    <div className="top-section" data-aos="fade-up" data-aos-duration="1000">
                        <img src="img/services/content-strategy.png" alt="mission" />
                        <h2 >Content Marketing</h2>
                    </div>
                    <div className="content" data-aos="fade-up" data-aos-duration="1000">
                        <p>At DocsPro, we specialize in crafting engaging content that positions our clients as thought leaders in their industries. With a proven track record in content excellence, we leverage our expertise to create compelling narratives that captivate audiences and establish our clients' authority in their fields.
                            <br></br>
                            <br></br>
                            Drawing from our wealth of experience, we develop content strategies tailored to showcase DocsPro clients' unique perspectives and expertise. By delivering high-quality content that resonates with their target audience, we help them build credibility, gain trust, and stand out as leaders in their respective domains. At DocsPro, we're dedicated to helping our clients achieve thought leadership through impactful storytelling and content excellence.</p>
                    </div>
                </div>
                <div className="service-section">
                    <div className="top-section" data-aos="fade-up" data-aos-duration="1000">
                        <img src="img/services/content.png" alt="mission" />
                        <h2 >Content Creation</h2>
                    </div>
                    <div className="content" data-aos="fade-up" data-aos-duration="1000">
                        <p>
                            In content creation, we specialize in producing visually captivating static, motion, and video content tailored to meet the diverse needs of our clients. Our focus extends beyond conventional methods, ensuring that every piece we create leaves a lasting impact on the audience.
                            <br></br>
                            <br></br>
                            Additionally, we provide supplementary training for doctors, equipping them with the skills and knowledge to effectively utilize content in their practice. By combining our expertise in content creation with targeted training sessions, we empower doctors to enhance their online presence and engage with their audience in meaningful ways.</p>
                    </div>
                </div>
                <div className="service-section">
                    <div className="top-section" data-aos="fade-up" data-aos-duration="1000">
                        <img src="img/services/online-rating.png" alt="mission" />
                        <h2 >Online Reputation and Review Management</h2>
                    </div>
                    <div className="content" data-aos="fade-up" data-aos-duration="1000">
                        <p>In online reputation and review management, we specialize in implementing strategies to enhance and manage online reputations, leveraging our experience with diverse medical professionals. Our approach is strategic, aimed at mitigating negative feedback and proactively building our clients' online presence.
                            <br></br>
                            <br></br>
                            We tailor strategies to address unique industry challenges, utilizing proven tactics and the latest technologies to ensure our clients' online reputation reflects their expertise and professionalism. With DocsPro, clients can trust in our expertise to safeguard and elevate their online presence effectively.</p>
                    </div>
                </div>
            </div>

        </div>
    )
}

export default ServiceList