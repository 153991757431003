import React from 'react';
import '../../../styles/WorksIntro.css'

function WorksIntro() {
  return (
    <div className='WorksIntro'>
      <div className="container">
        <p>

          Welcome to our showcase of transformative projects, where we unveil the success stories that exemplify our expertise in doctor marketing. Each project represents a collaborative effort resulting in tangible outcomes that elevate visibility, attract patients, and drive growth. Dive in to see how our strategic approach and creativity have helped medical practices thrive in the digital age, and discover how we can tailor our solutions to elevate your practice to new heights of success.
        </p>
      </div>
    </div>
  )
}

export default WorksIntro