import React from 'react';
import '../../../styles/ServiceIntro.css';

function ServiceIntro() {
    return (
        <div className='ServiceIntro'>
            <div className="container">
                <div className="row">
                    <div className="left-col custom-fade-right" data-aos="custom-fade-right" data-aos-duration="1000">
                        <h2><span>Innovative Healthcare Solutions: </span>Explore Our Dynamic Service Offerings</h2>
                        <p>
                            At DocsPro, we're deeply committed to enhancing the presence and influence of medical professionals through strategic branding and inventive marketing solutions. Our extensive range of services is meticulously crafted to empower doctors and healthcare providers to excel in a competitive environment and foster enduring relationships with their patients.
                            <br></br>
                            <br></br>
                            For doctors keen on actively participating in content creation, DocsPro offers comprehensive training sessions. Our aim is to equip doctors with the necessary skills to produce authentic and captivating video content, drawing from our successful training programs.
                            <br></br>
                            <br></br>
                            Here's how we can help:
                        </p>

                    </div>
                    <div className="right-col custom-fade-left" data-aos="custom-fade-left" data-aos-duration="1000">
                        <img src="img/service-intro.jpg" alt="doctor image" />
                    </div>
                </div>

            </div>
        </div>
    )
}

export default ServiceIntro