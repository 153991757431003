import React, { useEffect, useState } from 'react'
import Navbar from '../Components/Common/Navbar/Navbar'
import axios from 'axios'
import { useParams } from 'react-router-dom';
import { wordpressUrl } from '../Helpers/wordpressUrl';
import WorkDetails from '../Components/ProjectsPage/WorkDetails/WorkDetails';
import ServiceIntro from '../Components/ServicePage/ServiceIntro/ServiceIntro';
import Footer from '../Components/Common/Footer/Footer';
import WorksWeDid from '../Components/ProjectsPage/WorksWeDid/WorksWeDid';
import { Helmet } from 'react-helmet';

function ProjectIndividual() {
    const { slug } = useParams();
    const [work, setWork] = useState(null);
    useEffect(() => {


        axios.get(`${wordpressUrl}wp-json/wp/v2/works?slug=${slug}`)
            .then(res => {
                if (res.data.length > 0) {
                    setWork(res.data[0])
                    console.log(work + 'work array')
                } else {
                    console.log('No works found')
                }
            }).catch(err => {
                console.log(err)
            })
    }, [])
    return (
        <div className='ProjectIndividual'>
            <Helmet>
                <title>{work && work.title.rendered}</title>
                <link rel="canonical" href={`https://www.docspro.in/works/${slug}`}></link>
                {work && work.acf.meta_description && <meta name="description"
                    content={work && work.acf.meta_description}></meta>}
                <meta property="og:locale" content="en_US"></meta>
                <meta property="og:type" content="article"></meta>
                <meta property="og:title" content={work && work.title.rendered}></meta>
                {work && work.acf.meta_description && <meta property="og:description" content={work && work.acf.meta_description}></meta>}
                <meta property="og:url" content={`https://www.docspro.in/works/${slug}`}></meta>
                <meta property="og:site_name" content="DocsPro"></meta>
            </Helmet>
            <Navbar />
            <WorkDetails work={work} />
            <WorksWeDid work={work} />
            <Footer />



        </div>
    )
}

export default ProjectIndividual