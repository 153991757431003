import React from 'react'
import '../../../styles/BannerSection.css'

function BannerSection(props) {
    const { h2, imgUrl } = props;
    const backgroundImageStyle = { backgroundImage: `url('${imgUrl}')` };
    return (
        <div className='BannerSection'>
            <div className="img-container" style={backgroundImageStyle}>
            </div>
            <div className="overlay"></div>
            <div className="text">
                <h2>{h2}</h2>
            </div>
          
            <div class="custom-shape-divider-bottom-1708338597">
                <svg data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1200 120" preserveAspectRatio="none">
                    <defs>
                        <linearGradient id="gradient" x1="0%" y1="0%" x2="100%" y2="0%">
                            <stop offset="0%" style={{ stopColor: "#D9D9D9" }} />
                            <stop offset="0%" style={{ stopColor: "#D9D9D9" }} />
                        </linearGradient>
                    </defs>
                    <path d="M1200 120L0 16.48 0 0 1200 0 1200 120z" class="shape-fill"></path>
                </svg>
            </div>


        </div>
    )
}

export default BannerSection