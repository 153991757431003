

const BannerData= {
    AboutH2: "About",
    AboutImg: "../img/doctor-marketing.gif",
    HowWeWorkH2: "How We Work",
    HowWeWorkImg: "../img/doctor-marketing.gif",
    ServicesH2: "Services",
    ServicesImg: "./img/doctor-marketing.gif",
    BlogsH2: "Blogs",
    BlogsImg: "./img/doctor-marketing.gif",
    ProjectsH2:"Our Works",
    ProjectsImg:"./img/doctor-marketing.gif",
    CareerH2:"Career",
    CareerImg:"./img/doctor-marketing.gif",
    ContactH2:"Contact",
    ContactImg:"./img/doctor-marketing.gif",
}

export default BannerData