import React from 'react';
import '../../../styles/KeyMessage.css'
import { Link } from 'react-router-dom';

function KeyMessage() {
    return (
        <div className='KeyMessage'>
            <div className="container">
                <h2 data-aos="fade-up" data-aos-duration="1000">
                    With <span>DocsPro,</span> maintain an active digital presence that keeps you <span>ahead</span> of the competition. Don't just exist <span>online, thrive!</span>

                </h2>
                <div className="bottom" data-aos="fade-up" data-aos-duration="1000">
                    <p >DocsPro is tailored to meet the unique needs of healthcare professionals, ensuring that your online presence reflects your expertise and professionalism in the medical field.</p>
                    <Link to={'/services'} >Explore More</Link>
                </div>
            </div>
        </div>
    )
}

export default KeyMessage