import React from 'react';
import '../../../styles/BlogIntro.css'

function BlogIntro() {
    return (
        <div className='BlogIntro'>
            <div className="container">
                <p>
                    Welcome to our doctor marketing blog! Dive into industry insights, expert tips, and success stories curated by our seasoned professionals. Explore the latest trends and best practices in healthcare marketing, and gain actionable advice to enhance your online presence and thrive in today's competitive landscape. Join us on this journey of discovery and empowerment for your practice's success. Stay tuned for regular updates and invaluable resources to help you stay ahead in the ever-evolving world of healthcare marketing.
                </p>
            </div>
        </div>
    )
}

export default BlogIntro