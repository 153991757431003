import React from 'react'
import Navbar from '../Components/Common/Navbar/Navbar'
import BannerData from '../Helpers/BannerData';
import BannerSection from '../Components/Common/BannerSection/BannerSection';
import Footer from '../Components/Common/Footer/Footer';
import BlogList from '../Components/BlogPage/BlogList/BlogList';
import BlogIntro from '../Components/BlogPage/BlogIntro/BlogIntro';
import { Helmet } from 'react-helmet';

function Blogs() {
    const { BlogsH2, BlogsImg } = BannerData;

    return (
        <div className='Blogs'>
            <Helmet>
                <title>Blogs - DocsPro | Healthcare Marketing</title>
                <meta name="description"
                    content="Uncover DocsPro's operational approach on our 'How We Work' page. Explore our method, expertise, and commitment to revolutionizing healthcare marketing."></meta>
            </Helmet>
            <Navbar />
            <BannerSection h2={BlogsH2} imgUrl={BlogsImg} />
            <BlogIntro />
            <BlogList />
            <Footer />

        </div>
    )
}

export default Blogs