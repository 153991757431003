import React from 'react'
import BannerData from '../Helpers/BannerData';
import Navbar from '../Components/Common/Navbar/Navbar';
import BannerSection from '../Components/Common/BannerSection/BannerSection';
import ContactForm from '../Components/ContactPage/ContactForm/ContactForm';
import Footer from '../Components/Common/Footer/Footer';
import { Helmet } from 'react-helmet';

function Contact() {
    const { ContactH2, ContactImg } = BannerData;

    return (
        <div className='Contact'>
            <Helmet>
                <title>Contact - DocsPro | Healthcare Marketing</title>
                <meta name="description"
                    content="Reach out to DocsPro via our contact page. Let's connect and collaborate on transforming healthcare marketing for your practice."></meta>
            </Helmet>
            <Navbar />
            <BannerSection h2={ContactH2} imgUrl={ContactImg} />
            <ContactForm />
            <Footer />
        </div>
    )
}

export default Contact