import React from 'react'
import Navbar from '../Components/Common/Navbar/Navbar'
import BannerSection from '../Components/Common/BannerSection/BannerSection'
import BannerData from '../Helpers/BannerData';
import HowWeWorkIntro from '../Components/HowWeWorkPage/HowWeWorkIntro/HowWeWorkIntro';
import TimeLine from '../Components/HowWeWorkPage/TimeLine/TimeLine';
import Footer from '../Components/Common/Footer/Footer';
import { Helmet } from 'react-helmet';

function HowWeWork() {
    const { HowWeWorkH2, HowWeWorkImg } = BannerData;

    return (
        <div className='HowWeWork'>
            <Helmet>
                <title>How We Work - DocsPro | Healthcare Marketing</title>
                <meta name="description"
                    content="Dive into DocsPro's operational process on our 'How We Work' page. Explore our methods, expertise, and passion for reshaping healthcare marketing"></meta>
            </Helmet>
            <Navbar />
            <BannerSection h2={HowWeWorkH2} imgUrl={HowWeWorkImg} />
            <HowWeWorkIntro />
            <TimeLine />
            <Footer />
        </div>
    )
}

export default HowWeWork