import React, { useEffect, useRef, useState } from 'react';
import '../../../styles/WorkDetails.css';
import LightGallery from 'lightgallery/react';
import LGFullscreen from 'lightgallery/plugins/fullscreen';
import lgShare from 'lightgallery/plugins/share';
import lgZoom from 'lightgallery/plugins/zoom';
import lgRotate from 'lightgallery/plugins/rotate';
import lgAutoplay from 'lightgallery/plugins/autoplay';
import 'lightgallery/css/lightgallery.css';
import 'lightgallery/css/lg-zoom.css';
import 'lightgallery/css/lg-fullscreen.css';
import 'lightgallery/css/lg-thumbnail.css';
import 'lightgallery/css/lg-share.css';
import 'lightgallery/css/lg-rotate.css';
import 'lightgallery/css/lg-autoplay.css';

// import Plyr from 'plyr';
// import 'plyr/dist/plyr.css'

function WorkDetails({ work }) {
    const [images, setImages] = useState([]);
    const videoRef = useRef(null);

    useEffect(() => {
        // const player = new Plyr(videoRef.current);


        const extractImageLinksFromContent = (content, desiredSizes = ['526w', '768w', '724w', '1086w', '1360w', '1448w', '1810w', '1500w', '1081w', '1875w']) => {
            const parser = new DOMParser();
            const doc = parser.parseFromString(content, 'text/html');
            const imageElements = doc.querySelectorAll('img');

            const imageLinks = Array.from(imageElements).map((img) => {
                const srcset = img.getAttribute('srcset');
                if (srcset) {
                    const sizes = srcset.split(',').map((s) => s.trim().split(' '));
                    const desiredImage = sizes.find((size) => desiredSizes.includes(size[1]));
                    return desiredImage ? desiredImage[0] : null;
                } else {
                    return img.getAttribute('src');
                }
            });

            return imageLinks.filter((link) => link !== null);
        };

        if (work && work.acf && work.acf.photogallery) {
            const imageLinks = extractImageLinksFromContent(work.acf.photogallery, ['526w', '768w', '724w', '1086w', '1360w', '1448w', '1810w', '1500w', '1081w', '1875w']);
            setImages(imageLinks);
        }
    }, [work]);

    if (!work) {
        return (
            <div className="loading-container work-details">
                <span class="loader"></span>
            </div>
        )
    }

    return (
        <div className="WorkDetails">
            {work && (
                <div className="container">
                    <h2 className='title'>{work.title.rendered}</h2>
                    {work.acf.designation && <p className='designation'>{work.acf.designation}</p>}
                    <div className="row">
                        <div className="left-col">
                            <img src={work.acf.cover_image.sizes.medium}className='cover_image' alt="cover image" />
                        </div>
                        <div className="right-col">
                            <p>{work.acf && work.acf.description && <div dangerouslySetInnerHTML={{ __html: work.acf.description }}></div>}</p>

                        </div>

                    </div>

                </div>
            )}
        </div>
    );
}

export default WorkDetails;

