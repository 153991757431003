import React from 'react'
import Navbar from '../Components/Common/Navbar/Navbar'
import BannerSection from '../Components/Common/BannerSection/BannerSection'
import BannerData from '../Helpers/BannerData';
import ServiceIntro from '../Components/ServicePage/ServiceIntro/ServiceIntro';
import ServiceList from '../Components/ServicePage/ServiceList/ServiceList';
import Footer from '../Components/Common/Footer/Footer';
import { Helmet } from 'react-helmet';

function Services() {
  const { ServicesH2, ServicesImg } = BannerData;

  return (
    <div className='Services'>
      <Helmet>
        <title>Services - DocsPro | Healthcare Marketing</title>
        <meta name="description"
          content="Discover our comprehensive services at DocsPro. Learn how we elevate visibility, attract patients, and drive growth for medical practices with tailored strategies."></meta>
      </Helmet>
      <Navbar />
      <BannerSection h2={ServicesH2} imgUrl={ServicesImg} />
      <ServiceIntro />
      <ServiceList />
      <Footer />


    </div>
  )
}

export default Services