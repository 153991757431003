import React from 'react';
import '../../../styles/Mission.css';

function Mission() {
    return (
        <div className='Mission'>
            <div className="container">
                <div className="mission-section">
                    <div className="top-section"data-aos="fade-up" data-aos-duration="1000">
                        <img src="img/mission/mission.png" alt="mission" />
                        <h2 >Mission</h2>
                    </div>
                    <div className="content">
                        <ul>
                            <div className="points" data-aos="fade-up" data-aos-duration="1000">
                                <span><img src="img/check-mark.png" alt="check mark" /></span>
                                <p>Our mission is to empower doctors with tailored marketing strategies and tools, helping them connect with patients and grow their practices effectively</p>
                            </div>
                            <div className="points" data-aos="fade-up" data-aos-duration="1000">
                                <span><img src="img/check-mark.png" alt="check mark" /></span>
                                <p>We're dedicated to providing innovative solutions that enhance doctor-patient communication, foster trust, and elevate healthcare experiences.</p>
                            </div>
                            <div className="points" data-aos="fade-up" data-aos-duration="1000">
                                <span><img src="img/check-mark.png" alt="check mark" /></span>
                                <p>At the core of our mission is enabling doctors to stand out in their specialties, build strong personal brands, and make a positive impact in their communities.</p>
                            </div>
                            <div className="points" data-aos="fade-up" data-aos-duration="1000">
                                <span><img src="img/check-mark.png" alt="check mark" /></span>
                                <p>We aim to revolutionize doctor marketing by offering practical guidance, cutting-edge resources, and unparalleled support to medical professionals.</p>
                            </div>
                        </ul>
                    </div>
                </div>
                <div className="vision-section">
                    <div className="top-section"data-aos="fade-up" data-aos-duration="1000">
                        <img src="img/mission/binoculars.png" alt="vision" />
                        <h2>Vision</h2>
                    </div>
                    <div className="content">
                        <ul>
                            <div className="points" data-aos="fade-up" data-aos-duration="1000">
                                <span><img src="img/check-mark.png" alt="check mark" /></span>
                                <p>Foster a future where every doctor has the tools and resources to effectively communicate their expertise and connect with patients.</p>
                            </div>
                            <div className="points" data-aos="fade-up" data-aos-duration="1000">
                                <span><img src="img/check-mark.png" alt="check mark" /></span>
                                <p>Create a digital healthcare ecosystem where doctor-patient interactions are transparent, accessible, and impactful.</p>
                            </div>
                            <div className="points" data-aos="fade-up" data-aos-duration="1000">
                                <span><img src="img/check-mark.png" alt="check mark" /></span>
                                <p>Create a digital healthcare ecosystem where doctor-patient interactions are transparent, accessible, and impactful.</p>
                            </div>
                        </ul>
                    </div>
                </div>
                <div className="values-section">
                    <div className="top-section"data-aos="fade-up" data-aos-duration="1000">
                        <img src="img/mission/value.png" alt="value" />

                        <h2>Values</h2>
                    </div>
                    <div className="content">
                        <ul>
                            <div className="points" data-aos="fade-up" data-aos-duration="1000">
                                <span><img src="img/check-mark.png" alt="check mark" /></span>
                                <p>Integrity: Upholding honesty, transparency, and ethical conduct in all interactions and decisions. Integrity builds trust and credibility, both internally and externally, and forms the foundation for strong relationships and ethical business practices.</p>
                            </div>
                            <div className="points" data-aos="fade-up" data-aos-duration="1000">
                                <span><img src="img/check-mark.png" alt="check mark" /></span>
                                <p>Respect: Valuing diversity, inclusivity, and the dignity of all individuals. Respect fosters a supportive and inclusive work environment where everyone feels valued, heard, and appreciated, regardless of differences in background, perspectives, or experiences.</p>
                            </div>
                            <div className="points" data-aos="fade-up" data-aos-duration="1000">
                                <span><img src="img/check-mark.png" alt="check mark" /></span>
                                <p>Excellence: Striving for excellence in everything we do, from the quality of our products and services to the professionalism and expertise of our team. Excellence involves continuous improvement, attention to detail, and a commitment to delivering the highest standards of performance and customer satisfaction.</p>
                            </div>
                            <div className="points" data-aos="fade-up" data-aos-duration="1000">
                                <span><img src="img/check-mark.png" alt="check mark" /></span>
                                <p>Innovation: Embracing creativity, curiosity, and a willingness to challenge the status quo. Innovation drives progress and enables organizations to adapt to changing circumstances, seize new opportunities, and stay ahead of the competition by continually seeking out better ways of doing things.</p>
                            </div>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Mission