import React from 'react';
import '../../../styles/HowWeWorkIntro.css';

function HowWeWorkIntro() {
    return (
        <div className='HowWeWorkIntro'>
            <div className="container">
                <div className="row">
                    <div className="left-col custom-fade-right" data-aos="custom-fade-right" data-aos-duration="1000">
                        <h2><span>Crafting Success: </span>Revealing Our Proven Methodology for Practice Improvement</h2>
                        <p>
                            At DocsPro, our work begins with a meticulous strategy crafted to elevate your practice. We kick off with comprehensive consultations to understand your unique goals and challenges. Next, we delve into extensive market research and competitor analysis to tailor strategies that set you apart. Throughout the process, we maintain transparent communication, keeping you informed every step of the way. Our timeline is structured for efficiency and effectiveness, with clear milestones to track progress. From initial consultation to final implementation, we're committed to delivering results that exceed expectations and elevate your practice to new heights.
                        </p>

                    </div>
                    <div className="right-col custom-fade-left" data-aos="custom-fade-left" data-aos-duration="1000">
                        <img src="https://media.istockphoto.com/id/625727674/photo/marketing-strategy-business-concept.webp?b=1&s=170667a&w=0&k=20&c=s_rowDijoPx-aM9yowhrXMHFxcBr43RBJKSnn0f_eSE=" alt="doctor image" />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default HowWeWorkIntro