import React from 'react';
import '../../../styles/TimeLine.css';

function TimeLine() {
    return (
        <div className='TimeLine'>
            <div className="container">
                <div className="row">
                    <div className="card-container ">
                        <div className="phase" data-aos="fade-down" data-aos-duration="1000">
                            <h3>Phase 1</h3>
                            <p>(Months 1-2)</p>
                            <div className="dot">
                                <i class="fa-regular fa-circle-dot"></i>
                            </div>
                        </div>
                        <div className="content"data-aos="fade-down" data-aos-duration="1000">
                            <p>Client onboarding, initial consultation, competitor analysis, and brand
                                strategy development</p>
                        </div>
                    </div>
                    <div className="card-container">
                        <div className="phase"data-aos="fade-down" data-aos-duration="1000">
                            <h3>Phase 2</h3>
                            <p>(Months 2-3)</p>
                            <div className="dot">
                                <i class="fa-regular fa-circle-dot"></i>
                            </div>
                        </div>
                        <div className="content"data-aos="fade-down" data-aos-duration="1000">
                            <p>Brand identity creation, website development, and initiation of content
                                marketing campaigns.
                            </p>
                        </div>
                    </div>
                    <div className="card-container">
                        <div className="phase"data-aos="fade-down" data-aos-duration="1000">
                            <h3>Phase 3</h3>
                            <p>(Months 3-4)</p>
                            <div className="dot">
                                <i class="fa-regular fa-circle-dot"></i>
                            </div>
                        </div>
                        <div className="content"data-aos="fade-down" data-aos-duration="1000">
                            <p>Implementation of training services, online reputation management,
                                and continuous content creation.</p>
                        </div>
                    </div>
                    <div className="card-container">
                        <div className="phase last"data-aos="fade-down" data-aos-duration="1000">
                            <h3>Phase 4</h3>
                            <p>(Ongoing)</p>
                            <div className="dot">
                                <i class="fa-regular fa-circle-dot"></i>
                            </div>
                        </div>
                        <div className="content"data-aos="fade-down" data-aos-duration="1000">
                            <p>Regular performance analysis, refinement of strategies, and adaptation to
                                evolving digital trends.</p>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    )
}

export default TimeLine