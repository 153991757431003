import React from 'react'
import Navbar from '../Components/Common/Navbar/Navbar'
import HeroSection from '../Components/HomePage/HeroSection/HeroSection'
import KeyMessage from '../Components/HomePage/KeyMessage/KeyMessage'
import WhyChooseUs from '../Components/HomePage/WhyChooseUs/WhyChooseUs'
import Footer from '../Components/Common/Footer/Footer'
import Testimonials from '../Components/HomePage/Testimonials/Testimonials'
import { Helmet } from 'react-helmet'

function Home() {
  return (
    <div className='Home'>
      <Helmet>
        <title>DocsPro | Healthcare Marketing agency in Kozhikode (Calicut), Kerala</title>
        <meta name="description"
          content="DocsPro specializes in healthcare marketing, offering innovative solutions to enhance visibility, attract patients, and drive sustainable practice growth."></meta>
        <link rel="canonical" href="https://www.docspro.in/"></link>
        <meta property="og:title" content="DocsPro | Healthcare Marketing agency in Kozhikode  (Calicut), Kerala"></meta>
        <meta property="og:description" content="DocsPro specializes in healthcare marketing, offering innovative solutions to enhance visibility, attract patients, and drive sustainable practice growth."></meta>
        <meta property="og:url" content="https://www.docspro.in/"></meta>
        <meta property="og:site_name" content="DocsPro"></meta>
      </Helmet>
      <Navbar />
      <HeroSection />
      <KeyMessage />
      <WhyChooseUs />
      {/* <Testimonials /> */}
      <Footer />
    </div>
  )
}

export default Home