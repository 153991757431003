import React from 'react';
import '../../../styles/WhyChooseUs.css';

function WhyChooseUs() {
    return (
        <div className='WhyChooseUs'>
            <div className="container">
                <h2 data-aos="fade-up" data-aos-duration="1000">Why Choose Us</h2>
                <p className='whychooseus-desc' data-aos="fade-up" data-aos-duration="1000">At DocsPro, we're dedicated to helping you stand out from your competitors with customized digital strategies.</p>
                <div className="row">
                    <div className="card strategic" data-aos="fade" data-aos-duration="1000">
                        <h3>Content creation for healthcare</h3>
                        <p>Engaging and informative content is essential for attracting and retaining online visitors. With DocsPro, you can rely on our team of healthcare content experts to create high-quality content tailored to your specialty and target audience. Whether it's blog posts, articles, or social media content, we'll help you share your knowledge and connect with your audience  in meaningful ways.</p>
                        <span>01</span>
                    </div>
                    <div className="card brand" data-aos="fade" data-aos-duration="1000">
                        <h3>Professional Website Development</h3>
                        <p>Your website is often the first point of contact between you and potential patients. Our team of experts specializes in designing and developing professional websites that not only look great but also effectively communicate your expertise and services. From user-friendly layouts to compelling content, we ensure that your website stands out for all the right reasons.
                        </p>
                        <span>02</span>

                    </div>
                    <div className="card Personalized" data-aos="fade" data-aos-duration="1000">
                        <h3>Tailored Digital Strategies</h3>
                        <p>We understand that generic approaches don't cut it in the competitive healthcare industry. That's why we craft personalized digital strategies designed specifically for healthcare professionals. Whether you're a solo practitioner or part of a larger organization, we'll tailor our approach to meet your unique needs and goals.
                        </p>
                        <span>03</span>

                    </div>
                    <div className="card Excellence" data-aos="fade" data-aos-duration="1000">
                        <h3>Expertise in the Medical Field</h3>
                        <p>With DocsPro, you can trust that your online presence accurately represents your knowledge and proficiency in the medical field. We are committed to showcasing your skills and qualifications in a way that resonates with patients and peers alike.</p>
                        <span>04</span>

                    </div>
                    <div className="card Track" data-aos="fade" data-aos-duration="1000">
                        <h3>Proven Track Record</h3>
                        <p>At DocsPro, we pride ourselves on our track record of success in helping healthcare professionals like you thrive in the digital landscape. With years of experience and numerous satisfied clients under our belt, you can trust that we have the expertise and capabilities to deliver results.</p>
                        <span>05</span>

                    </div>
                    <div className="card Trends" data-aos="fade" data-aos-duration="1000">
                        <h3>Search Engine Optimization (SEO)</h3>
                        <p>Ranking high on search engine results is crucial for increasing your online visibility and attracting more patients. Our SEO specialists utilize industry best practices to optimize your website for relevant keywords and improve its search engine rankings. By staying on top of the latest trends and algorithms, we'll help you climb the search engine ranks and stay ahead of the competition.</p>
                        <span>06</span>

                    </div>
                </div>

            </div>
        </div>
    )
}

export default WhyChooseUs