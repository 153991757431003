import React from 'react';
import '../../../styles/Profile.css';
import PatientsCounter from '../PatientsCounter/PatientsCounter';

function Profile() {
    return (
        <div className='Profile'>
            <div className="container">
                <div className="row">
                    <div className="left-col custom-fade-right" data-aos="custom-fade-right" data-aos-duration="1000">
                        <h2><span>Discover Our Story:</span> Revolutionizing Doctor Branding for the Modern Era</h2>
                        <p>
                            At DocsPro, we're driven by a shared passion for revolutionizing the way doctors approach branding and marketing in the modern healthcare landscape. Our journey began with a simple yet profound belief: that every doctor deserves the opportunity to build a powerful brand that authentically reflects their expertise, values, and commitment to patient care.
                            <br></br>
                            <br></br>
                            Founded by a team of dedicated experts with diverse backgrounds in marketing, design, and healthcare, we're on a mission to empower healthcare professionals with the tools, strategies, and insights they need to stand out, connect with their audience, and make a lasting impact. Get to know the visionaries behind our mission, discover our story, and join us in shaping the future of doctor branding and marketing.

                        </p>

                    </div>
                    <div className="right-col custom-fade-left" data-aos="custom-fade-left" data-aos-duration="1000">
                        <img src="img/profile-section-img.jpg" alt="doctor image" />
                    </div>
                </div>
                {/* <PatientsCounter /> */}

            </div>

        </div>
    )
}

export default Profile