import axios from 'axios'
import React, { useEffect, useState } from 'react'
import '../../../styles/WorkList.css'
import { Link } from 'react-router-dom'
import { wordpressUrl } from '../../../Helpers/wordpressUrl';
import lozad from 'lozad';
function WorkList() {
    const [works, setWorks] = useState([]);
    const [loading, setLoading] = useState(true);
    const [fetchWorksIds, setFetchedWorksIds] = useState(new Set());
    const [page, setPage] = useState(1);

    const perPage = 100;
    const lastPageIndex = page * perPage;

    const fetchWorks = () => {

        // 
        axios.get(`${wordpressUrl}wp-json/wp/v2/works?per_page=${perPage}&page=${page}`)
            .then((res) => {
                const newWorks = res.data.filter((work) => !fetchWorksIds.has(work.id));
                setWorks((prevWorks) => [...prevWorks, ...newWorks]);
                newWorks.forEach((work) => fetchWorksIds.add(work.id));
                setLoading(false);
            })
            .catch((err) => {
                setLoading(false);


            })
    }

    useEffect(() => {
        fetchWorks();
    }, [page]);

    // skeleton loading animation 

    useEffect(() => {
        const observer = lozad('.lozad', {
            loaded: function (el) {

                el.classList.add('lazy-loaded');
                el.addEventListener('load', () => {
                    el.classList.remove('skeleton-loader');
                });
            },
            rootMargin: '-20px 0px',
        });
        observer.observe();

        return () => {

        };
    }, []);

    if (loading) {
        return (
            <div className="loading-container">
                <span class="loader"></span>
            </div>

        );
    }


    const handleLoadMore = () => {
        setPage((prevPage) => prevPage + 1);
        fetchWorks(); // Fetch new blogs when the page button is clicked
    };



    return (
        <div className='WorkList'>
            <div className="container">

                <div className="row">
                    {works.length === 0 ? (
                        <div className='no-works-message'>
                            <h3>No works in this category.</h3>
                        </div>
                    ) : (
                        works.map(work => (
                            <div className="card" data-aos="fade" data-aos-duration="1000" key={work.id}>
                                <Link to={`/our-works/${work.slug}`}>
                                    <div className="img-container">
                                        {work.acf.cover_image && (
                                            <img src={work.acf.cover_image.sizes.medium_large} alt={work.acf.name} className='lozad skeleton-loader' />
                                        )}
                                    </div>
                                    <div className="content">
                                        <h3>{work.title.rendered}</h3>
                                        {work.acf.designation && <p>{work.acf.designation}</p>}
                                    </div>
                                </Link>
                            </div>
                        ))
                    )}
                </div>

            </div>

        </div>
    )
}

export default WorkList