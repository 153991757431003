import './App.css';
import React, { useEffect, useState } from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import Home from './Pages/Home';
import About from './Pages/About';
import ScrollToTop from './Components/ScrollToTop/ScrollToTop';
import Services from './Pages/Services';
import Projects from './Pages/Projects';
import Blogs from './Pages/Blogs';
import Contact from './Pages/Contact';
import HowWeWork from './Pages/HowWeWork';
import ProjectIndividual from './Pages/ProjectIndividual';
import BlogSinglePg from './Pages/BlogSinglePg';
import Whatsapp from './Components/Common/Whatsapp_btn/Whatsapp';
import PreLoader from './Components/Common/PreLoader/PreLoader';

function App() {
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    const fetchData = async () => {
      // Introduce a minimum delay of 2 seconds before checking document.readyState
      await new Promise(resolve => setTimeout(resolve, 2600));

      if (document.readyState !== 'loading') {
        setLoading(false);
      }
    };

    fetchData();
  })
  return (
    <div className="App">
      <BrowserRouter>
        <ScrollToTop />
        <Whatsapp />
        <PreLoader loading={loading} />
        

        <Routes>

          <Route path='/' element={<Home />} />
          <Route path='/about' element={<About />} />
          <Route path='/how-we-work' element={<HowWeWork />} />
          <Route path='/services' element={<Services />} />
          <Route path='/our-works' element={<Projects />} />
          <Route path='/our-works/:slug' element={<ProjectIndividual />} />
          <Route path='/blogs' element={<Blogs />} />
          <Route path='/blogs/:slug' element={<BlogSinglePg />} />
          <Route path='/contact' element={<Contact />} />

        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
