import React from 'react';
import '../../../styles/HeroSection.css';

function HeroSection() {
  return (
    <div className='HeroSection'>
      <div className="container">
        <video src="video/herosection-video.mp4" autoPlay loop muted></video>
        <div className="content">
          <h1 data-aos="fade" data-aos-duration="1000">What's Next?<br></br> Soar to <span>New</span> Heights in the <span>Digital World</span> with <span>DocsPro</span>


          </h1>
          <p data-aos="fade" data-aos-duration="1000">
          Take Your Online Presence to the Next Level. With DocsPro, you can increase your reach, engage with your audience effectively, and establish a strong digital footprint that sets you apart from the competition. Let us help you shine in the digital world!

          </p>
        </div>
      </div>
    </div>
  )
}

export default HeroSection